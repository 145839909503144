import React from 'react'

function Contact() {
  return (
    <div>        
        <h1>Contact</h1>
        <h2>Architecturing an React Application using Redux Toolkit</h2>
        <p>This will serve as a template for creating React Applications.  Starting here or another page will appear links.</p>
    </div>
  )
}

export default Contact