import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { updatePost } from './../store/postSlice'
import { updatePostsAsync } from './../store/post.actions'

export default function UpdatePost() {  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //In order to update state, we need to pull in the params for the id we want to edit
  const params = useParams(); //2. used gets segments from the URL, 
  let searchid = Number(params.id) //this hook gets the :/id (dynamic identifier) from the url /:id
  
  //Step 3: select the updated store
  //next step, dispatch updated store global store and the API
  const posts = useSelector(state => state.posts)  //3. get posts from store
  const existingPost = posts.items.find(post => post.id === searchid)    //4. find existingpost with param id
  
  //set updated title and updated body to e.target.value in the form
  const [updatedTitle, setUpdatedTitle] = useState(existingPost?.title)
  const [updatedBody, setUpdatedBody] = useState(existingPost?.body)

  //dispatch updated title and body to the global store
  const handleUpdate = e => { //click handleEdit to reset global state using local state
    e.preventDefault() //prevents page refresh - no posts

    //step 1: gets variables needed to update store and Step 2: dispatch to reducer   
    //reducer transforms data - updates existing post in global store 
    dispatch(updatePost({  
      userId: existingPost.userId,
      id: existingPost.id,
      title: updatedTitle, 
      body: updatedBody        
    })) //updates store
    
    //Step 4 - dispatch updated post to API using Thunk 
    dispatch(updatePostsAsync({ existingPost }))

    navigate(`/posts/post-list`)  //go back to all posts
  }

  return (

  <div className="component-card" key={params.id}>
      <form className="edit-form" onSubmit={handleUpdate}>
        <input
          type="text"
          name="title"
          className="edit-form-input"
          value={updatedTitle}
          onChange={e => setUpdatedTitle(e.target.value)}  //get previous result and then update          
        />
        
        <textarea
          type="text"
          name="body"
          className="edit-form-textarea"
          value={updatedBody}
          onChange={e => setUpdatedBody(e.target.value)}    //get previous result and then update
        />
        <button type="submit">update</button>
      </form>                  
    </div>
  )
}

