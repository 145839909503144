import { useState  } from "react";
import PostExcerpt from "./PostExcerpt";
import { useSelector } from 'react-redux'
//import { Route } from 'react-router-dom'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getPostsAsync } from '../store/post.actions'  //fetches post
import { changed } from "../store/postSlice";

export default function Search() {
  const [query, setQuery] = useState('');  //gets value 
  const [searchResults, setSearchResults] = useState([]);  

  const dispatch = useDispatch()


    //normally, when app loads useEffect loads global state of data fetched from api->store (loads once) 
    //but we only want to see updated global state if we added or deleled a post, if (change===true) 
    //otherwise show all previous changes    
    useEffect(() => {     
      if(changed) {        
        dispatch(getPostsAsync())  //dispatch all data from API to store onload (not with each onsubmit)
      }              
    }, [dispatch])
  

  const posts = useSelector(state => state.posts) 
  const postItems = useSelector(state => state.posts.items) 
  //const searchFilterArray = Array.isArray(posts) ? posts.items.filter(post => post.title.toLowerCase().includes(query)) : null; //this will display as you type 
  const searchFilterArray = postItems?.filter(post => post.title.toLowerCase().includes(query))  

  let handleChange = e => {  
    //setSearchResults()  
    setSearchResults(searchFilterArray)  
    setQuery(e.target.value)  
  }

  let handleSubmit = async (e) => {  
    e.preventDefault();

    //ssetSearchResults()
    setSearchResults(searchFilterArray)  //displays search filter results after submitted
    setQuery('')
  }
   
  return (
    <div className="search-component-card">
      <h1 className="form-header">Look up a post</h1>
      {/* <Route path="/search-app/specific-purpose">
        Use this to direct users to a search page that serves a specific purpose.
      </Route> */}

        <form className="search-form" data-testid="search-form" onSubmit={handleSubmit}>

          <input 
            data-testid="search-form-input"
            type="text"
            name="query"
            value={query}
            className="search-form-input"
            placeholder="type a search term"
            onChange={handleChange}  
          />
          <button 
            data-testid="search-form-id"
            className='search-form-button' 
            type="submit"
          >
            Search
          </button>

          <p>{posts?.status}</p>          
        </form>

        <div className="component-div">            
            {searchResults?.map(post => 
                <PostExcerpt
                  post={post}
                />            
            )}
        </div>
    </div>
  )
}
