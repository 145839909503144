import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom' 
import { lazy, Suspense } from 'react';
//import Posts from './components/Posts';
//import PostExcerpt from './components/PostExcerpt';
//import PostList from './components/PostList';
import Search from './components/Search';
import UpdatePost from './components/UpdatePost';
import DetailedPost from './components/DetailedPost';
import AddPost from './components/AddPost';
import Comments from './components/Comments';
import './App.css';
import Nav from './components/Nav';
import Contact from './components/Contact';
import About from './components/About';

const Posts = lazy(() => import('./components/Posts'));
const PostExcerpt = lazy(() => import('./components/PostExcerpt'));
const PostList = lazy(() => import('./components/PostList'));

export default function App() {
  return (
    <div className="App">      
        <Router>
          <Nav />
          {/* //all pages that user may visit */}
          <Suspense fallback={<h1>Loading...</h1>}>
            <Routes>
              <Route path="/" element={<Navigate replace to="/posts" />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/posts" element={<Posts />}>  
                <Route path="search-app" element={<Search />} />
                <Route path="post-list" element={<PostList />} />            
                <Route path="add-post" element={<AddPost />} />            
                <Route path="detailed-post/:id" element={<DetailedPost />}>                
                  <Route path="comments" element={<Comments />} />
                </Route> 
                <Route path="post-excerpt/:id" element={<PostExcerpt />} />              
              </Route>  

              <Route path="/update/:id" element={<UpdatePost />} />
              <Route path="/add-post" element={<AddPost />} />
            </Routes>
          </Suspense>

        </Router>
    </div>
  );
}



