import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Link, Outlet } from 'react-router-dom';
import { deletePostsAsync } from '../store/post.actions';
import { useDispatch } from 'react-redux';
import { deletePost } from '../store/postSlice';

import { useParams } from 'react-router-dom'

export default function SinglePostPage() {
  const params = useParams(); //2. gets the id from edit-form/id
  const searchid = Number(params.id) 

  //Step 3: select the updated store
  //next step, dispatch deleted post to global store and  API  
  const posts = useSelector(state => state.posts)
  const existingPost = posts.items.find(post => post.id === searchid)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  //dispatch entire existing post to the global store
  const handleDelete = (e) => {
    e.preventDefault()
    //reducer transforms data - delete a post from the global store
    dispatch(deletePost(existingPost))  

    //step 4 - dispatch deleted post to remove it from the API
    dispatch(deletePostsAsync(existingPost))  //send entire post and api will destructure for id

    navigate(`/posts/post-list`)  //go back to all posts
  }

  return (
      <article className="component-card detailed-post" key={existingPost.id}>
        <h4 id="title" className="component-card-title">{existingPost.title}</h4>
        <p className="component-card-body">{existingPost.body}</p>    
        <div>
            <Link to={`/update/${existingPost.id}`} className="pagelink">
              <button>Edit</button>
            </Link>
            
            <button onClick={handleDelete}>Delete</button>

            <Link to={`comments`} className="pagelink">
              <button>Comment</button>
            </Link> 

            <Outlet/>

        </div> 
      </article>       
  )
}