import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addPost } from './../store/postSlice'
import { addPostsAsync } from './../store/post.actions'
//import { nanoid } from 'nanoid'

export default function AddPost() { 
  const [title, setTitle] = useState('') //start off empty  same as setBody('')
  const [body, setBody] = useState('') //start off empty  same as setTitle('')

  // const [post, setPost] = useState({
  //   title, 
  //   body
  // })

  //const { title, body } = post;

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const randomid = Math.floor(Math.random() * 9999)
  
  //dispatch updated title and body to the global store
  const handleAdd = e => { //click handleEdit to reset global state using local state
    e.preventDefault() //prevents page refresh - no posts
  
    const newPost = {
      userId: 1,
      id: randomid,
      title, 
      body     
    }
        
    console.log(newPost)

    //step 1: gets variables needed toadd post Step 2: dispatch to reducer   
    //reducer transform data - adds post to global store 
    dispatch(addPost(newPost)) //updates store

    // dispatch(notification({

    // }))
    
    //step 4 - dispatch new post to API using Thunk 
    dispatch(addPostsAsync(newPost))

    navigate(`/posts/post-list`)  //go back to all posts
  }

  return (
    <div className="component-card">
      <h1>Add a new Post</h1>
        <form className="add-form" onSubmit={ handleAdd }>
            <input
                type="text"
                name="title"
                className="add-form-input"
                value={title}
                onChange={e => setTitle(e.target.value)}    //get previous result and then update
            />
            
            <textarea
                type="text"
                name="body"
                className="add-form-textarea"
                value={body}
                onChange={e => setBody(e.target.value)}    //get previous result and then update
            />
            <button type="submit">add</button>
        </form>                  
    </div>
  )
}

